import * as React from 'react'
import { graphql } from 'gatsby'

import ChannelCard from '../components/ChannelCard'

export const ChannelCards = ({ slice, context }) => {
    return (
        <section className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 sm:gap-8 md:gap-12 mb-6">
            {slice.items.map((item, i) => (
                <div key={i}>
                    <ChannelCard
                        channel={item.channel?.document}
                        type={context.type}
                    />
                </div>
            ))}
        </section>
    )
}

export const query = graphql`
    fragment FrontPageDataBodyChannelCards on PrismicFrontPageDataBodyChannelCards {
        items {
            channel {
                document {
                    ... on PrismicChannel {
                        uid
                        data {
                            name {
                                text
                            }
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            quality: 100
                                            placeholder: BLURRED
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    fragment ReplayPageDataBodyChannelCards on PrismicReplayPageDataBodyChannelCards {
        items {
            channel {
                document {
                    ... on PrismicChannel {
                        uid
                        data {
                            name {
                                text
                            }
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            quality: 100
                                            placeholder: BLURRED
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    fragment ProgramsPageDataBodyChannelCards on PrismicProgramsPageDataBodyChannelCards {
        items {
            channel {
                document {
                    ... on PrismicChannel {
                        uid
                        data {
                            name {
                                text
                            }
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            quality: 100
                                            placeholder: BLURRED
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
