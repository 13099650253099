import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Text = ({ slice, context }) => {
    return (
        <section className="pt-5 pb-10 prose prose-lg max-w-none text-gray-600">
            <PrismicRichText field={slice.primary?.content?.richText} />
        </section>
    )
}

export const query = graphql`
    fragment FrontPageDataBodyText on PrismicFrontPageDataBodyText {
        primary {
            content {
                richText
            }
        }
    }
    fragment ReplayPageDataBodyText on PrismicReplayPageDataBodyText {
        primary {
            content {
                richText
            }
        }
    }
    fragment ShowsPageDataBodyText on PrismicShowsPageDataBodyText {
        primary {
            content {
                richText
            }
        }
    }
    fragment ProgramsPageDataBodyText on PrismicProgramsPageDataBodyText {
        primary {
            content {
                richText
            }
        }
    }
    fragment ProgramsPageDataChannelBodyText on PrismicProgramsPageDataChannelBodyText {
        primary {
            content {
                richText
            }
        }
    }
`
