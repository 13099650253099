import * as React from 'react'
import { graphql } from 'gatsby'

import AdSlot from '../components/AdSlot'

export const AdSlotSlice = ({ slice, context }) => {
    return <AdSlot html={slice.primary?.code?.text} />
}

export const query = graphql`
    fragment FrontPageDataBodyAdSlot on PrismicFrontPageDataBodyAdSlot {
        primary {
            code {
                text
            }
        }
    }
    fragment ReplayPageDataBodyAdSlot on PrismicReplayPageDataBodyAdSlot {
        primary {
            code {
                text
            }
        }
    }
    fragment ShowsPageDataBodyAdSlot on PrismicShowsPageDataBodyAdSlot {
        primary {
            code {
                text
            }
        }
    }
    fragment ProgramsPageDataBodyAdSlot on PrismicProgramsPageDataBodyAdSlot {
        primary {
            code {
                text
            }
        }
    }
    fragment ProgramsPageDataChannelBodyAdSlot on PrismicProgramsPageDataChannelBodyAdSlot {
        primary {
            code {
                text
            }
        }
    }
`
